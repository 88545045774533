import React from "react"
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import Footer from "../../../components/App/Footer"
import Schnittstellen from '../../../components/ProductDetails/netstorsys/schnittstellen'
import Seo from "../../../components/App/Seo"

const SchnittstellenPage = () => {
  return (
    <Layout>
      <Seo title={"Moderne Schnittstellen zur grenzenlosen Interaktion."}
           description={"Moderne Webservice Schnittstellen auf Basis von HTTP/Rest für die Interoperabilität " +
           "mit anderen Systemen wie Shopsystemen, ERP oder CRM Systemen."}
           image={"/images/og/netstorsys-main-og.png"}
      />
      <Navbar />
      <Schnittstellen />
      <Footer />
    </Layout>
  );
}

export default SchnittstellenPage
