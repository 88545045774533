import React from "react"
import apiHeader from "../../../assets/images/products/netstorsys/api_header.svg"
import wmInterfaces from "../../../assets/images/products/netstorsys/wm-interfaces.svg"
import project3 from "../../../assets/images/products/netstorsys/shoperp-api.svg"
import Sidebar from "./Sidebar"
import { Col, Container, Row } from "react-bootstrap"
import WichtigeMerkmale from "../WichtigeMerkmale"
import LexikonBadgesRandomSection from "../../lexikon/LexikonBadgesRandomSection"
import FeatureSection from "../../App/FeatureSection"

const Interfaces = () => {

 return (
    <>
      <section className="services-details-area ptb-100">
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12} >
              <div className="services-details-image">
                <img src={apiHeader} alt="API - Application Programming Interfaces" />
              </div>

              <div className="services-details-desc">
                <span className="sub-title">NETSTORSYS</span>
                <h1 className={"pt-3"}>Schnittstellen und APIs </h1>
                <p>
                  NETSTORSYS bietet moderne Webservice Schnittstellen auf Basis von HTTP/Rest an. Neben einer
                  einfacheren Anbindung im Vergleich zu SOAP basierten Schnittstellen, ist auch das Testen der
                  Schnittstellen deutlich komfortabler und kann sogar von einer Betriebssystem-Kommandozeile initiiert
                  werden. Dass sowohl JSON als auch XML als Datenformat bzw. Payload gewählt werden kann, steigert die
                  Interoperabilität mit anderen Systemen.
                </p>

                <WichtigeMerkmale items={[
                  "REST Webservices",
                  "JSON und XML",
                  "flexible Anbindung von Schnittstellen aller Art",
                  "Polling Services",
                ]} image={<img src={wmInterfaces}
                               alt="Schnittstellen Merkmale"
                               className={"ml-lg-4 ml-0"} />} />

                <p>
                  Notwendige XML oder JSON Attribute werden in der NETSTORSYS Dokumentation ausführlich dargestellt.
                  Beispielrequests um Aufträge einfach in das System zu spielen, werden hierbei genauso geliefert, wie
                  fachliche Erläuterungen der Attribute um deren Bedeutung im WMS Kontext.
                </p>
                <p>
                  Falls das bestehende System keine Möglichkeit bietet Informationen in NETSTORSYS einzuspielen, kann
                  die Anbindung auch aus der anderen Richtung erfolgen. Hierbei ist es egal, welche Art der
                  Schnittstelle vorhanden ist. Ob nun eine einfach REST-API, SOAP oder doch nur ein Datei-Export -
                  NETSTORSYS kann damit umgehen und die Daten entsprechend verarbeiten. Weiterhin ist eine Rückmeldung
                  (Lagerbestand, Auftragsstatus, etc.) an das Vorsystem kein Problem.
                </p>

                <h3>Relevantes aus dem Lexikon</h3>
                <LexikonBadgesRandomSection product={"Netstorsys"}/>

              </div>
            </Col>

            <Col lg={4} md={12}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </section>

      <FeatureSection backgroundColorClass={"bg-f1f8fb"}
                      imagePosition={"left"}
                      image={<img src={project3} alt={"APIs in der Praxis"} />}
                      header={"APIs in der Praxis"}
                      textBlock1={<span>Mit den vorhanden REST und SOAP Schnittstellen von NETVERSYS integrieren Sie Ihre Vorsysteme wie ERP,
                Shop oder Customer Relationship Management ohne Probleme. Moderne Payloads wie JSON und XML machen es
                Ihnen leicht Daten zwischen den Systemen zu transportieren.</span>}
                      textBlock2={<span>Auch die Möglichkeit asynchrone Übertragungen, für noch mehr Ausfallsicherheit und Zero-Downtime
                Deployments, zu integrieren ist ein Pluspunkt in der NETSTORSYS Featureliste.</span>}
      />
    </>
  )
}

export default Interfaces
